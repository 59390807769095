import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function DialogCustom({ open, message, action }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{message.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => action(false)}>
            Cancelar
          </Button>
          <Button onClick={() => action(true)}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
