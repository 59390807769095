import React, { useEffect, useState } from "react";
import { insertDataJson, updateItem, getData } from "../Tools/helpers";
import Alert from "@mui/material/Alert";

import TableHistoryCustom from "../components/TableHistoryCustom";
import DialogCustom from "../components/DialogCustom";

const Discharge = () => {
  const [isDialog, setIsDialog] = useState(false);
  const [itemCancel, setItemCancel] = useState(0);
  const [dataDischarges, setDataDischarges] = useState({
    method_payment: 0,
    amount: 0,
    description: "",
    segment: "",
    type: "",
    staff_id: 0,
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const getDischarges = async () => {
    const route = "Discharge";
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const setIds = async () => {
    const user = await JSON.parse(localStorage.getItem("USER_IUMV"));
    setDataDischarges({
      ...dataDischarges,
      university_id: user.university_id,
      staff_id: user.staff_id,
    });
  };

  const setState = (e) => {
    setDataDischarges({
      ...dataDischarges,
      [e.target.name]: e.target.value,
    });
  };

  const FieldsCustomTable = [
    { name: "id", label: "ID" },
    { name: "name", label: "Campus" },
    { name: "description", label: "Concepto" },
    { name: "type", label: "Tipo" },
    { name: "register_date", label: "Fecha" },
    { name: "amount", label: "Importe" },
    { name: "segment", label: "Segmento" },
    { name: "method_payment", label: "Forma de pago" },
    { name: "status_description", label: "Estatus" },
    { name: "approved_by", label: "Apropado por" },
    { name: "approved_date", label: "Fecha aprobación" },
    { name: "status", label: "" },
  ];

  const saveDischarge = async () => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    if (
      dataDischarges.method_payment === 0 ||
      dataDischarges.amount === 0 ||
      dataDischarges.amount === "" ||
      dataDischarges.description === "" ||
      dataDischarges.segment === "" ||
      dataDischarges.type === ""
    ) {
      setMessage({
        status: true,
        message: "Todos los campos son requeridos",
        label: "error",
      });
      return;
    }

    setMessage({
      status: true,
      message: "Guardando...",
      label: "info",
    });

    const route = "Discharge";
    const send = await insertDataJson(route, dataDischarges);

    if (send.code === 201) {
      setMessage({
        status: true,
        message: "Se ha guardado correctamente",
        label: "success",
      });
      getDischarges();
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  const activeDialog = (row) => {
    setItemCancel(row);
    setIsDialog(true);
  };

  const cancelDischarge = async (option) => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    setIsDialog(false);
    if (!option) {
      return;
    }
    setMessage({
      status: true,
      message: "Cancelando...",
      label: "info",
    });

    const dataStatus = {
      status: 1,
      status_description: 'Cancelado'
    };

    const route = "Discharge";
    const send = await updateItem(`${route}/${itemCancel.id}`, dataStatus);
    if (send.code === 200) {
      setMessage({
        status: true,
        message: "Se ha cancelado correctamente",
        label: "success",
      });
      getDischarges();
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  useEffect(() => {
    setIds();
    getDischarges();
  }, []);

  return (
    <div>
      <DialogCustom
        open={isDialog}
        message={{ title: "Cancelar", message: "¿Desea cancelar el registro de ingreso?" }}
        action={cancelDischarge}
      />
      <div className="flex items-center justify-center">
        <div className="flex flex-col justify-center">
          <div className="mt-1">
            <div className="my-10">
              <p className="text-4xl underline">Egreso</p>
            </div>
          </div>
          <div className="flex justify-center it">
            <div className="grid grid-cols-2">
              <div className="mr-20">
                <div className="grid grid-cols-2">
                  <div className="mt-5">
                    <p className="text-sm">Importe:</p>
                  </div>
                  <div className="mt-5">
                    <input
                      className="border w-full p-1"
                      type="number"
                      name="amount"
                      value={dataDischarges.amount}
                      onChange={setState}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="mt-1">
                    <p className="text-sm">Concepto:</p>
                  </div>
                  <div className="mt-1">
                    <input
                      className="border w-full p-1"
                      type="text"
                      name="description"
                      value={dataDischarges.description}
                      onChange={setState}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="mt-1">
                    <p className="text-sm">Segmento:</p>
                  </div>
                  <div className="mt-1">
                    <input
                      className="border w-full p-1"
                      type="text"
                      name="segment"
                      value={dataDischarges.segment}
                      onChange={setState}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-2">
                  <div className="mt-5">
                    <p className="text-sm">Tipo:</p>
                  </div>
                  <div className="mt-5">
                    <input
                      className="border w-full p-1"
                      type="text"
                      name="type"
                      value={dataDischarges.type}
                      onChange={setState}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="mt-1">
                    <p className="text-sm">Forma de pago:</p>
                  </div>
                  <div className="mt-1">
                    <select
                      className="px-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      name="method_payment"
                      value={dataDischarges.method_payment}
                      onChange={setState}
                    >
                      <option value="0">Seleccione una opción</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Tarjeta">Tarjeta</option>
                      <option value="Deposito">Deposito</option>
                      <option value="Transferencia">Transferencia</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {message.status ? (
              <Alert className="mt-2" severity={message.label}>
                {message.message}
              </Alert>
            ) : (
              ""
            )}
          </div>
          <div className="my-10 flex justify-end">
            <button
              onClick={() => saveDischarge()}
              className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
            >
              AGREGAR
            </button>
          </div>
        </div>
      </div>
      <div>
        <TableHistoryCustom
          fields={FieldsCustomTable}
          data={data}
          actionType={["cancel"]}
          action={activeDialog}
        />
      </div>
    </div>
  );
};

export default Discharge;
