import React, { useEffect, useState } from "react";
import { getData } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const Notification = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const [listUniversities, setListUniversities] = useState([]);

  const FieldsCustom = [
    { name: "title", label: "Titulo", required: "true" },
    { name: "description", label: "Descripción", required: "true" },
    { name: "validity_date", label: "Vigencia", required: "false", type: "date" },
    {
      name: "university_id",
      label: "Campus",
      required: "true",
      type: "select",
      list: listUniversities,
      field_select_description: "name",
    },
    {
      name: "status",
      label: "Estatus",
      required: "true",
      type: "select",
      list: [
        { id: 1, status: "Activo campus" },
        { id: 2, status: "Activo todos los campus" },
        { id: 3, status: "Inactivo" },
      ],
      field_select_description: "status",
    },
  ];

  const FieldsCustomTable = [
    { name: "title", label: "Titulo" },
    { name: "description", label: "Descripción" },
    { name: "validity_date", label: "Vigencia" },
    {
      name: "university_name",
      label: "Campus",
    },
    {
      name: "status_description",
      label: "Estatus",
    },
  ];

  const routeForm = "Notification";

  const getNotifications = async () => {
    const route = routeForm;
    const send = await getData(route);
    if (send.code === 200) {
      send.data.map((item) => {
        item["status_description"] =
          parseInt(item.status) === 1
            ? "Campus"
            : parseInt(item.status) === 2
            ? "Todos"
            : "Inactivo";
      });
      setDataTable(send.data);
    }
  };

  const getUniversities = async () => {
    const route = "University";
    const send = await getData(route);
    if (send.code === 200) {
      setListUniversities(send.data);
    }
  };

  useEffect(() => {
    getNotifications();
    getUniversities();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="NOTIFICACIONES"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getNotifications}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      <div className="flex-1">
        <TableCustom
          fields={FieldsCustomTable}
          setDataForm={setDataForm}
          data={dataTable}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default Notification;
