import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getData } from "../Tools/helpers";

import FormCustom from "../components/FormCustom";

const RegisterUser = () => {
  const [searchParams] = useSearchParams();
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(true);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const FieldsCustom = [
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "phone", label: "Teléfono", required: "false" },
    { name: "email", label: "Email", required: "true" },
    { name: "start_date", label: "Fecha de inicio", required: "true", type: "date" },
    { name: "end_date", label: "Fecha de termino", required: "true", type: "date" },
    { name: "password", label: "Contraseña", required: "true", type: "password" },
  ];

  const navigate = useNavigate();
  const routeForm = "StudentRegister";
  const token = searchParams.get("token");

  const getSudent = async () => {
    setDataForm({
      ...dataForm,
      token: token,
    });
    const send = await getData(`${routeForm}/${token}`);
    if (send.code === 200) {
      setDataForm({
        token: token,
        id: send.data.id,
        code: send.data.code,
        name: send.data.name,
        curp: send.data.curp,
        phone: send.data.phone,
        email: send.data.email,
        start_date: send.data.start_date,
        end_date: send.data.end_date,
      });
    }
  };

  const redirectStudent = () => {
    navigate("/");
  };

  useEffect(() => {
    getSudent();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl p-20">
        <FormCustom
          fields={FieldsCustom}
          title="ACTUALIZA TUS DATOS"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={redirectStudent}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default RegisterUser;
