import React, { useEffect, useState } from "react";
import { getData } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const FourMonth = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [listFourMonth, setListFourMonth] = useState([]);
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const FieldsCustom = [
    { name: "description", label: "Mes", required: "true" },
    {
      name: "four_month_id",
      label: "Cuatrimestre",
      required: "true",
      type: "select",
      list: listFourMonth,
      field_select_description: "description",
    },
    { name: "payday_limit", label: "Fecha limite de pago", required: "true", type: "number" },
  ];

  const FieldsCustomTable = [
    { name: "description", label: "Mes", required: "true" },
    { name: "four_month", label: "Cuatrimestre", required: "true" },
    { name: "payday_limit", label: "Limite de pago", required: "true", type: "date" },
  ];

  const routeForm = "Month";

  const getMonths = async () => {
    const route = routeForm;
    const send = await getData(route);
    if (send.code === 200) {
      setDataTable(send.data);
    }
  };

  const getFourMonth = async () => {
    const route = "FourMonth";
    const send = await getData(route);
    if (send.code === 200) {
      setListFourMonth(send.data);
    }
  };

  useEffect(() => {
    getFourMonth();
    getMonths();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="DATOS DE CUATRIMESTRE"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getMonths}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      <div className="flex-1">
        <TableCustom
          fields={FieldsCustomTable}
          setDataForm={setDataForm}
          data={dataTable}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default FourMonth;
