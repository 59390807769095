import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../contextApp";
import { useParams } from "react-router-dom";
import { getData, getDateNow } from "../Tools/helpers";

import PaymentCashModule from "./components/PaymentCashModule";

const PaymentAdditional = () => {
  const { studentData, setStudentData } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [infoPayment, setInfoPayment] = useState({
    description: "",
    year: 0,
    amount: "",
    status: 0,
    evidence: "",
    surcharge: "",
    type: "",
    method_payment: "",
    info_bill: {},
    register_date: "",
    discount: "",
    month_id: "",
    student_id: "",
  });

  const getProcedures = async () => {
    const route = "Procedure";
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const { id } = useParams();
  const getStudent = async () => {
    const route = "Student";
    const send = await getData(`${route}/${id}`);
    if (send.code === 200) {
      setStudentData(send.data);
    }
  };

  const getInfoPayment = (e) => {
    let procedure_id = e.target.value;
    const procedure = data.filter((item) => parseInt(item.id) === parseInt(procedure_id))[0];
    const date = getDateNow();
    setInfoPayment({
      ...infoPayment,
      amount: procedure.cost,
      surcharge: 0,
      description: procedure.description,
      month: date.monthDescription[0].toUpperCase() + date.monthDescription.substring(1),
      year: date.year,
      four_month: "",
      month_id: "",
      student_id: studentData.student_id,
      method_payment: "Efectivo",
      info_bill: {
        business_name: "",
        rfc: "",
        cfdi: "",
      },
      type: "procedure",
      discount: 0,
      you_can_pay: "true",
    });
  };

  useEffect(() => {
    getStudent();
    getProcedures();
  }, []);

  return (
    <div className="flex-1">
      <div className="grid grid-cols-2">
        <div className="flex justify-center">
          <div className="max-w-sm">
            <p className="font-bold mb-5">Adicionales</p>
            <div>
              <p>Seleccione un archivo</p>
              <select
                className="px-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={getInfoPayment}
              >
                <option value="0">Seleccione una opción</option>
                {data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-20 px-5">
              <p>
                * Todo documento que pague en esta sistema deberá guardar el folio generado en el
                recibo de pago.{" "}
              </p>
            </div>
          </div>
        </div>
        <PaymentCashModule
          infoPayment={infoPayment}
          setInfoPayment={setInfoPayment}
          refreshList={getProcedures}
        />
      </div>
    </div>
  );
};

export default PaymentAdditional;
