import React, { Fragment, useState } from "react";
import { Routes, Route } from "react-router-dom";

import AdminNav from "./AdminNav";
import Notification from "./Notification/Notification";
import University from "./University/University";
import Career from "./Career/Career";
import Procedure from "./Procedure/Procedure";
import Staff from "./Staff/Staff";
import Position from "./Position/Position";
import User from "./User/User";
import FourMonth from "./FoutMonth/FourMonth";
import GeneralConfig from "./GeneralConfig/GeneralConfig";

const AdminContainer = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));

  const directorGeneralRoute = [
    {
      active: 0,
      name: "Notificaciones",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Campus",
      key: "University",
      route: "/University",
      component: <University />,
    },
    {
      active: 2,
      name: "Carreras",
      key: "Career",
      route: "/Career",
      component: <Career />,
    },
    {
      active: 3,
      name: "Trámites",
      key: "Procedure",
      route: "/Procedure",
      component: <Procedure />,
    },
    {
      active: 4,
      name: "Personal",
      key: "Staff",
      route: "/Staff",
      component: <Staff />,
    },
    {
      active: 5,
      name: "Puestos",
      key: "Position",
      route: "/Position",
      component: <Position />,
    },
    {
      active: 6,
      name: "Estudiantes",
      key: "Student",
      route: "/Student",
      component: <User />,
    },
    {
      active: 7,
      name: "Cuatrimestres",
      key: "FourMonth",
      route: "/FourMonth",
      component: <FourMonth />,
    },
    {
      active: 8,
      name: "Configuraciones",
      key: "Config",
      route: "/Config",
      component: <GeneralConfig />,
    },
  ];

  const directorRoute = [
    {
      active: 0,
      name: "Notificaciones",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Campus",
      key: "University",
      route: "/University",
      component: <University />,
    },
    {
      active: 2,
      name: "Carreras",
      key: "Career",
      route: "/Career",
      component: <Career />,
    },
    {
      active: 6,
      name: "Estudiantes",
      key: "Student",
      route: "/Student",
      component: <User />,
    },
    {
      active: 7,
      name: "Cuatrimestres",
      key: "FourMonth",
      route: "/FourMonth",
      component: <FourMonth />,
    },
  ];

  const getRoutesCustom = () => {
    let routeCustom = directorRoute;

    if (
      userData.abbreviation === "director_general" ||
      userData.abbreviation === "gerente_general" ||
      userData.abbreviation === "rector"
    ) {
      return (routeCustom = directorGeneralRoute);
    }
    return routeCustom;
  };

  return (
    <Fragment>
      <AdminNav routes={getRoutesCustom()} />
      <div className="flex-1 px-10">
        <Routes>
          <Route exact path="/" element={<Notification />} />
          {getRoutesCustom().map((route) => (
            <Route exact path={route.route} element={route.component} key={route.key} />
          ))}
        </Routes>
      </div>
    </Fragment>
  );
};

export default AdminContainer;
