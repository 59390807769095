import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const PaymentNav = ({ routes }) => {
  const [active, setActive] = useState(0);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/Admin/Notification") {
      setActive(0);
    } else if (location.pathname === "/Admin/University") {
      setActive(1);
    } else if (location.pathname === "/Admin/Career") {
      setActive(2);
    } else if (location.pathname === "/Admin/Procedure") {
      setActive(3);
    } else if (location.pathname === "/Admin/Staff") {
      setActive(4);
    } else if (location.pathname === "/Admin/Position") {
      setActive(5);
    } else if (location.pathname === "/Admin/Student") {
      setActive(6);
    } else if (location.pathname === "/Admin/FourMonth") {
      setActive(7);
    } else if (location.pathname === "/Admin/Config") {
      setActive(8);
    }
  }, [location.pathname]);

  return (
    <nav className="flex items-center justify-between flex-wrap px-10 divide-y divide-solid">
      <div className="w-full bg-gray-500 justify-end mb-14 block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-xs">
          {routes.map((item) => (
            <Link
              key={item.key}
              className="block lg:inline-block lg:mt-0 text-white hover:text-red-400 border-blue-500 rounded py-1 px-10"
              style={{ background: active === item.active ? "#e00e09" : "" }}
              to={item.key}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default PaymentNav;
