import React from "react";

const PaymentTransfer = ({ infoPayment, setInfoPayment, handleFile }) => {
  return (
    <div>
      <div className="mt-1">
        <div className="flex items-baseline">
          <input
            className="mr-2 cursor-pointer"
            type="radio"
            name="method_payment"
            value="Transferencia"
            onChange={(e) => {
              setInfoPayment({
                ...infoPayment,
                [e.target.name]: e.target.value,
              });
            }}
            checked={infoPayment.method_payment === "Transferencia" ? true : false}
          />
          <p className="font-bold mt-10">Cargo por transferencia:</p>
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="mt-5">
          <p className="ml-5 text-sm">Folio de transferencia u operación:</p>
        </div>
        <div className="mt-5 col-span-2">
          <input
            className="border w-full p-1"
            name="folio_transfer"
            value={infoPayment.folio_transfer ? infoPayment.folio_transfer : ""}
            onChange={(e) => {
              setInfoPayment({
                ...infoPayment,
                [e.target.name]: e.target.value,
                status_description: 'Pendiente',
                status: 1
              });
            }}
            disabled={infoPayment.method_payment === "Tarjeta" ? true : false}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="mt-1">
          <p className="ml-5 text-sm">Fecha:</p>
        </div>
        <div className="mt-1 col-span-2">
          <input
            type="date"
            name="transfer_date"
            value={infoPayment.transfer_date ? infoPayment.transfer_date : ""}
            className="border w-full p-1"
            onChange={(e) => {
              setInfoPayment({
                ...infoPayment,
                [e.target.name]: e.target.value,
              });
            }}
            disabled={infoPayment.method_payment === "Tarjeta" ? true : false}
          />
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="mt-1">
          <p className="ml-5 text-sm">Adjuntar comprobante:</p>
        </div>
        <div className="mt-1 col-span-2">
          <input
            type="file"
            onChange={handleFile}
            className="border w-full p-1"
            disabled={infoPayment.method_payment === "Tarjeta" ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentTransfer;
