import React from "react";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";

import Logo from "./logo_iumv.png";

const PDFReceipt = ({ name, curp, item, year }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 9,
      paddingTop: 30,
      lineHeight: 1.5,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
    logo: {
      display: "block",
      position: "absolute",
      width: "40%",
      resizeMode: "contain",
      top: -90,
    },
    item: {
      marginTop: 5,
    },
    title: {
      fontWeight: "bold",
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.item}>
          <View style={styles.item}>
            <Image src={Logo} style={styles.logo} />
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Nombre del Alumno:</Text>
            <Text>{name}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>CURP:</Text>
            <Text>{curp}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Folio:</Text>
            <Text>{item.code}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Forma de pago:</Text>
            <Text>{item.method_payment}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Fecha de pago:</Text>
            <Text>{item.register_date}</Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.title}>Concepto:</Text>
            <Text>{`Pago de mensualidad ${item.description} año ${year}`}</Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.title}>Costo Colegiatura:</Text>
            <Text>{item.monthly_payment}</Text>
          </View>

          {item.paid_status === "surchargePaid" ? (
            <View style={styles.item}>
              <Text style={styles.title}>Recargo:</Text>
              <Text>{item.surcharge_payment}</Text>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default PDFReceipt;
