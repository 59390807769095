import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

import PaymentNav from "./PaymentNav";
import PaymentList from "./PaymentList";
import PaymentDetail from "./PaymentDetail";
import PaymentAdditional from "./PaymentAdditional";
import PaymentHistory from "./PaymentHistory";

const PaymentContainer = () => {
  return (
    <Fragment>
      <PaymentNav />
      <div className="flex-1 px-10">
        <Routes>
          <Route exact path="/" element={<PaymentList />} />
          <Route exact path="/PaymentDetail/:id" element={<PaymentDetail />} />
          <Route exact path="/PaymentAdditional/:id" element={<PaymentAdditional />} />
          <Route exact path="/PaymentHistory/:id" element={<PaymentHistory />} />
        </Routes>
      </div>
    </Fragment>
  );
};

export default PaymentContainer;
