import React, { useEffect, useState } from "react";
import { getData } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const Position = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const FieldsCustom = [{ name: "description", label: "Descripcón", required: "true" }];

  const routeForm = "Position";

  const getPosition = async () => {
    const route = routeForm;
    const send = await getData(route);
    if (send.code === 200) {
      setDataTable(send.data);
    }
  };

  useEffect(() => {
    getPosition();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="LISTA DE PUESTOS"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="description"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getPosition}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      <div className="flex-1">
        <TableCustom
          fields={FieldsCustom}
          setDataForm={setDataForm}
          data={dataTable}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default Position;
