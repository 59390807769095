import React, { useEffect, useState } from "react";
import { getData } from "../Tools/helpers";

import NotificationCustom from "../components/NotificationCustom";

const Notification = () => {
  const [listNotifications, setListNotifications] = useState([]);

  const getNotifications = async () => {
    const route = "Notification";
    const user = await JSON.parse(localStorage.getItem("USER_IUMV"));
    const send = await getData(`${route}/${user.university_id}`);
    if (send.code === 200) {
      setListNotifications(send.data);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return <NotificationCustom notifications={listNotifications} />;
};

export default Notification;
