import React from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";

import Login from "./Login/Login";
import RegisterUser from "./RegisterUser/RegisterUser";
import Container from "./Container";

const Main = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Register" element={<RegisterUser />} />
        <Route exact path="/*" element={<Container />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Main;
