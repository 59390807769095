import React from "react";
import AppProvider from "./modules/contextApp";
import Main from "./modules/Main";

function App() {
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}

export default App;
