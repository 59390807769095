import React from "react";
import Slider from "infinite-react-carousel";

const NotificationCustom = ({ notifications }) => {
  const settings = {
    autoplay: true,
  };

  return notifications.length > 0 ? (
    <Slider {...settings} dots>
      {notifications.map((item) => (
        <div key={item.description} className="flex-1 px-10">
          <div className="grid mt-10 p-20 flex-1 h-80 bg-slate-300 items-center justify-center">
            <div className="grid flex-column">
              <p className="text-xl text-center font-bold">{item.title}</p>
              <p className="text-xl text-center">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  ) : (
    <div className="flex-1 px-10">
      <div className="flex mt-10 p-20 flex-1 h-80 bg-slate-300 items-center justify-center">
        <p className="text-xl">
          Estimado alumno, aquí podrá ver notificaciones y anuncios del campus.
        </p>
      </div>
    </div>
  );
};

export default NotificationCustom;
