import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../contextApp";
import { useParams } from "react-router-dom";
import { getData } from "../Tools/helpers";

import TableHistoryCustom from "../components/TableHistoryCustom";

const PaymentHistory = () => {
  const { setStudentData } = useContext(AppContext);
  const [data, setData] = useState([]);

  const { id } = useParams();
  const getStudent = async () => {
    const route = "Student";
    const send = await getData(`${route}/${id}`);
    if (send.code === 200) {
      setStudentData(send.data);
    }
  };

  const getPaymentHistory = async () => {
    const route = "PaymentFourMonth";
    const send = await getData(`${route}/${id}`);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const FieldsCustomTable = [
    { name: "code", label: "Folio" },
    { name: "description", label: "Concepto" },
    { name: "four_month_description", label: "Cuatrimestre" },
    { name: "month_description", label: "Mes" },
    { name: "amount", label: "Costo" },
    { name: "surcharge", label: "Recargo" },
    { name: "discount", label: "Descuento" },
    { name: "register_date", label: "Fecha" },
    { name: "method_payment", label: "Forma de pago" },
    { name: "evidence", label: "Evidencia" },
  ];

  useEffect(() => {
    getStudent();
    getPaymentHistory();
  }, []);

  return (
    <div className="flex-1">
      <div className="flex-1 mb-10">
        <TableHistoryCustom fields={FieldsCustomTable} data={data} />
      </div>
    </div>
  );
};

export default PaymentHistory;
