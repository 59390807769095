import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../contextApp";

const PaymentNav = () => {
  const { studentData } = useContext(AppContext);
  const [active, setActive] = useState(0);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname.startsWith("/Payment/PaymentDetail")) {
      setActive(0);
    } else if (location.pathname.startsWith("/Payment/PaymentAdditional")) {
      setActive(1);
    } else if (location.pathname.startsWith("/Payment/PaymentHistory")) {
      setActive(2);
    }
  }, [location.pathname]);

  return (
    <nav className="flex items-center justify-between flex-wrap px-10 divide-y divide-solid">
      <div>
        <p>
          Alumno: <b>{studentData.name}</b> | Carrera: <b>{studentData.career_name}</b>
        </p>
      </div>
      <div className="w-full justify-end mb-14 block flex-grow lg:flex lg:items-center lg:w-auto">
        {studentData.name !== "" ? (
          <>
            <div className="text-xs bg-slate-500 rounded">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-red-400 border-blue-500 rounded py-1 px-10"
                style={{ background: active === 0 ? "#e00c09" : "" }}
                to={`PaymentDetail/${studentData.student_id}`}
              >
                Cuatrimestre
              </Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-red-400 border-blue-500 rounded py-1 px-10"
                style={{ background: active === 1 ? "#e00c09" : "" }}
                to={`PaymentAdditional/${studentData.student_id}`}
              >
                Adicional
              </Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-red-400 border-blue-500 rounded py-1 px-10"
                style={{ background: active === 2 ? "#e00c09" : "" }}
                to={`PaymentHistory/${studentData.student_id}`}
              >
                Historial
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </nav>
  );
};

export default PaymentNav;
