import React, { useEffect, useState } from "react";
import { getData } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const Student = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const [listCareers, setListCareers] = useState([]);
  const [listUniversities, setListUniversities] = useState([]);
  const [lisrPermissions, setListPermissions] = useState([]);

  const FieldsCustom = [
    { name: "code", label: "Código del alumno", required: "true" },
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "phone", label: "Teléfono", required: "false" },
    { name: "email", label: "Email", required: "true" },
    {
      name: "university_id",
      label: "Campus",
      required: "true",
      type: "select",
      list: listUniversities,
      field_select_description: "name",
    },
    {
      name: "career_id",
      label: "Carrera",
      required: "true",
      type: "select",
      list: listCareers,
      field_select_description: "name",
    },
    { name: "start_date", label: "Fecha de inicio", required: "true", type: "date" },
    { name: "end_date", label: "Fecha de termino", required: "true", type: "date" },
    { name: "password", label: "Contraseña", required: "true", type: "password" },
    {
      name: "permission_id",
      label: "Permiso de usuario",
      required: "true",
      type: "select",
      list: lisrPermissions,
      field_select_description: "description",
    },
  ];

  const FieldsCustomTable = [
    { name: "code", label: "Código", required: "true" },
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "phone", label: "Teléfono", required: "false" },
    { name: "email", label: "Email", required: "true" },
    { name: "career_name", label: "Carrera", required: "true" },
    { name: "university_name", label: "Campus", required: "true" },
    { name: "descripcion_permissions", label: "Permiso de usuario", required: "true" },
  ];

  const routeForm = "Student";

  const getStaff = async () => {
    const route = routeForm;
    const send = await getData(route);
    console.log(send);
    if (send.code === 200) {
      setDataTable(send.data);
    }
  };

  const getCareers = async () => {
    const route = "Career";
    const send = await getData(route);
    if (send.code === 200) {
      setListCareers(send.data);
    }
  };

  const getUniversities = async () => {
    const route = "University";
    const send = await getData(route);
    if (send.code === 200) {
      setListUniversities(send.data);
    }
  };

  const getPermissions = async () => {
    const route = "Permission";
    const send = await getData(route);
    if (send.code === 200) {
      const data = send.data.filter((item) => item.abbreviation === "student");
      setListPermissions(data);
    }
  };

  useEffect(() => {
    getStaff();
    getCareers();
    getUniversities();
    getPermissions();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="DATOS DE ESTUDIANTES"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getStaff}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      <div className="flex-1">
        <TableCustom
          fields={FieldsCustomTable}
          setDataForm={setDataForm}
          data={dataTable}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default Student;
