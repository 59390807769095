import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";

import IOSSwitch from "./IOSSwitch";
import { insertDataJson, updateItem } from "../../Tools/helpers";
import PaymentCard from "./PaymentCard";
import PaymentTransfer from "./PaymentTransfer";

const PaymentCashModule = ({ infoPayment, setInfoPayment, refreshList, studentData }) => {
  const [isBilled, setIsBilled] = useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const [infoBill, setInfoBill] = useState({
    business_name: "",
    rfc: "",
    cfdi: "",
    bill: "",
  });

  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const setState = (e) => {
    setInfoBill({
      ...infoBill,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    const maxSizeFile = event.target.files[0].size;
    setMessage({
      status: false,
      message: "",
    });
    if (maxSizeFile > 3000000) {
      setMessage({
        status: true,
        label: "error",
        message: "El archivo debe pesar menos de 3M",
      });
      return;
    }
    reader.onload = function () {
      console.log(reader.result);
      setInfoPayment({
        ...infoPayment,
        evidence: reader.result,
      });
    };
  };

  useEffect(() => {
    if (infoPayment.paid_status === "paid" || infoPayment.paid_status === "surchargePaid") {
      setIsPaidStatus(true);
    } else {
      setIsPaidStatus(false);
    }

    // if (infoPayment.you_can_pay === "false") {
    //   setMessage({
    //     status: true,
    //     message: "Debe pagar primero los meses anteriores",
    //     label: "warning",
    //   });
    // } else if (infoPayment.you_can_pay === "true") {
    //   setMessage({
    //     status: false,
    //     message: "",
    //     label: "",
    //   });
    // }

  }, [infoPayment]);

  const savePaymentStudent = async (action) => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    if(infoPayment.amount === "" || infoPayment.amount === 0){
      setMessage({
        status: true,
        message: "Capture un monto válido",
        label: "error",
      });
      return;
    }

    if (
      isBilled &&
      (infoBill.length === 0 ||
        infoBill.business_name === "" ||
        infoBill.rfc === "" ||
        !infoBill.cfdi ||
        !infoBill.business_name ||
        !infoBill.rfc ||
        !infoBill.cfdi)
    ) {
      setMessage({
        status: true,
        message: "Los campos de facturación son requeridos",
        label: "error",
      });
      return;
    }

    if (infoPayment.method_payment === "Transferencia") {
      if (
        infoPayment.folio_transfer === "" ||
        infoPayment.transfer_date === "" ||
        infoPayment.evidence === ""
      ) {
        setMessage({
          status: true,
          message: "Los campos de transferencia son requeridos",
          label: "error",
        });
        return;
      }
    }

    if (infoPayment.email_student === "" || !infoPayment.email_student) {
      setMessage({
        status: true,
        message: "El correo electrónico es requerido",
        label: "error",
      });
      return;
    }

    setMessage({
      status: true,
      message: "Guardando...",
      label: "info",
    });

    infoPayment["info_bill"] = infoBill;

    const route = "PaymentFourMonth";
    let send = "";
    if (action === "pagar") {
      send = await insertDataJson(route, infoPayment);
    } else {
      send = await updateItem(
        `${route}/${infoPayment.info_bill.payment_four_month_id}`,
        infoPayment
      );
    }

    if (send.code === 201 || send.code === 200) {
      setMessage({
        status: true,
        message: "Se ha guardado correctamente",
        label: "success",
      });
      setIsPaidStatus(true);
      refreshList(infoPayment.year);
      setInfoBill({
        business_name: "",
        rfc: "",
        cfdi: "",
        bill: "",
      });
      return;
    }
    if (send.code === 400) {
      setMessage({
        status: true,
        message: "Ya se ha registrado este pago",
        label: "error",
      });
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, vuelva a seleccionar el mes",
      label: "error",
    });
  };

  const downloadEvidence = (evidence, code, description) => {
    var a = document.createElement("a");
    a.href = evidence;
    a.download = `${code}_${studentData.name}_${description}`;
    a.click();
  };

  return (
    <div className="flex justify-center">
      <div className="w-full">
        <p className="font-bold">Datos Bancarios</p>
        <p className="mb-5">
          (Pago en {infoPayment.method_payment})
          {infoPayment.evidence !== "" ? (
            <a
              href="#"
              onClick={() =>
                downloadEvidence(infoPayment.evidence, infoPayment.code, infoPayment.description)
              }
              target="_blank"
            >
              <li className="ml-2 cursor-pointer fa-solid fa-file-arrow-down text-xl text-green-500"></li>
            </a>
          ) : null}
        </p>
        <div className="flex justify-end items-center">
          <label className="pr-5">Facturar</label>{" "}
          <IOSSwitch onChange={(e) => setIsBilled(e.target.checked)} color="success" />
        </div>
        <div>
          <p className="font-bold">Concepto:</p>
          <p className="ml-5 text-sm">
            Descripción: <b>{infoPayment.description}</b>
          </p>
          <p className="ml-5 text-sm">
            Año: <b>{infoPayment.year}</b>
          </p>
          <p className="ml-5 text-sm">
            Cuatrimestre: <b>{infoPayment.four_month}</b>
          </p>
          <p className="ml-5 text-sm">
            Mes: <b>{infoPayment.month}</b>
          </p>
          <p className="ml-5 text-sm">
            Importe: 
            {/* <b>${infoPayment.amount ? parseFloat(infoPayment.amount).toFixed(2) : ""}</b> */}
            <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="amount"
                value={infoPayment.amount}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              />
          </p>
          {infoPayment.surcharge ? (
            <p className="ml-5 text-sm">
              Recargo: 
              {/* <b>${parseFloat(infoPayment.surcharge).toFixed(2)}</b> */}
              <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="surcharge"
                value={infoPayment.surcharge}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              />
            </p>
          ) : null}

          <p className="ml-5 text-sm">
            Descuento: 
            {/* <b>${infoPayment.amount ? parseFloat(infoPayment.amount).toFixed(2) : ""}</b> */}
            <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="discount"
                value={infoPayment.discount}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              />
          </p>
          <p className="ml-5 text-sm">
            TOTAL:{" "}
            <b className="text-lg">
              $
              {infoPayment.amount
                ? ((parseFloat(infoPayment.amount) + parseFloat(infoPayment.surcharge ==="" ? 0 : parseFloat(infoPayment.surcharge))) - ( infoPayment.discount ==="" ? 0 : parseFloat(infoPayment.discount))  ).toFixed(2)
                : ""}
            </b>
          </p>
        </div>
        {userData.abbreviation === "student" ? (
          <>
            {/* <PaymentCard setInfoPayment = {setInfoPayment} infoPayment = {infoPayment} /> */}
            <PaymentTransfer
              setInfoPayment={setInfoPayment}
              infoPayment={infoPayment}
              handleFile={handleFile}
            />
          </>
        ) : null}

        {isBilled ? (
          <div>
            <div className="mt-1">
              <p className="font-bold mt-10">Facturar:</p>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-5">
                <p className="ml-5 text-sm">Razón social:</p>
              </div>
              <div className="mt-5 col-span-2">
                <input
                  name="business_name"
                  value={infoBill.business_name ? infoBill.business_name : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-1">
                <p className="ml-5 text-sm">RFC:</p>
              </div>
              <div className="mt-1 col-span-2">
                <input
                  name="rfc"
                  value={infoBill.rfc ? infoBill.rfc : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-1">
                <p className="ml-5 text-sm">Uso CFDI:</p>
              </div>
              <div className="mt-1 col-span-2">
                <input
                  name="cfdi"
                  value={infoBill.cfdi ? infoBill.cfdi : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="my-10 flex justify-end">
              {infoPayment.you_can_pay === "true" || infoPayment.you_can_pay  === "false" ? (
                !infoPayment.info_bill.bill === "" ||
                (!infoPayment.info_bill.bill && isPaidStatus) ? (
                  <button
                    onClick={() => savePaymentStudent("facturar")}
                    className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
                  >
                    FACTURAR
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
        ) : null}
        <div>
          {message.status ? (
            <Alert className="mt-2" severity={message.label}>
              {message.message}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <div className="grid mt-5 grid-cols-3">
          <div className="mt-1">
            <p className="ml-5 font-bold">Correo del alumno:</p>
          </div>
          <div className="mt-1 col-span-2">
            <input
              type="email"
              name="email_student"
              value={infoPayment.email_student ? infoPayment.email_student : ""}
              onChange={(e) => {
                setInfoPayment({
                  ...infoPayment,
                  [e.target.name]: e.target.value,
                });
              }}
              className="border w-full p-1"
            />
          </div>
        </div>
        <div className="my-10 flex justify-end">
          {infoPayment.you_can_pay === "true" || infoPayment.you_can_pay  === "false" ? (
            isPaidStatus ? (
              <button className="px-10 py-2 bg-red-700 rounded-lg text-white font-bold">
                {infoPayment.info_bill.bill !== null && infoPayment.info_bill.bill !== ""
                  ? "PAGADO Y FACTURADO"
                  : "PAGADO"}
              </button>
            ) : (
              <button
                onClick={() => savePaymentStudent("pagar")}
                className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
              >
                PAGAR
              </button>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PaymentCashModule;
