import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Nav = ({ routes }) => {
  const [active, setActive] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/Notification") {
      setActive(0);
    } else if (location.pathname === "/Payment") {
      setActive(1);
    } else if (location.pathname === "/Entry") {
      setActive(2);
    } else if (location.pathname === "/Discharge") {
      setActive(3);
    } else if (location.pathname === "/Request") {
      setActive(4);
    } else if (location.pathname === "/Dashboard") {
      setActive(5);
    } else if (location.pathname === "/Admin") {
      setActive(6);
    } else if (location.pathname === "/Activity") {
      setActive(7);
    }else if (location.pathname === "/RequestTransfer") {
      setActive(8);
    }
  }, [location.pathname]);

  return (
    <nav className="flex menu-bar items-center justify-between flex-wrap px-10 divide-y divide-solid">
      <div className="flex items-center flex-shrink-0 text-white mr-6 lg:hidden">
        <Link to="/">
          <img src="http://qa.spimexicocorporativo.com/logo_iumv.png" alt="IUMV" />
        </Link>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-red-700 border-red-700 hover:text-red-400 hover:border-red-400">
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-sm lg:flex-grow">
          {routes.map((item) => (
            <Link
              key={item.key}
              className="block mt-4 lg:inline-block lg:mt-0 text-red-700 hover:text-red-400 border-blue-500 rounded py-1 px-10"
              style={{ background: active === item.active ? "#e00c09" : "" }}
              to={item.key}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div>
          <Link
            className="inline-block text-red-700 text-sm px-4 py-2 leading-none hover:border-red-400 hover:text-red-400"
            to="Login"
          >
            Cerrar Sesión
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
