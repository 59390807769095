import React, { useContext, useEffect, useState } from "react";
import { getData, insertDataJson } from "../Tools/helpers";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AppContext } from "../contextApp";

import TextField from "@mui/material/TextField";

import PaymentCashModule from "./components/PaymentCashModule";
import PDFReceipt from "../Tools/PDFReceipt";

const PaymentDetail = () => {
  const { studentData, setStudentData } = useContext(AppContext);
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));
  const [data, setData] = useState([]);
  const [listYear, setListYear] = useState([]);
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const [infoPayment, setInfoPayment] = useState({
    description: "",
    year: 0,
    amount: "",
    status: 0,
    status_description: 'Aprobado',
    evidence: "",
    surcharge: "",
    type: "",
    method_payment: "Transferencia",
    info_bill: {},
    register_date: "",
    folio_transfer: "",
    transfer_date: "",
    discount: "",
    month_id: "",
    student_id: "",
    email_student: "",
  });

  const { id } = useParams();
  const getStudent = async () => {
    const route = "Student";
    const send = await getData(`${route}/${id}`);
    if (send.code === 200) {
      setStudentData(send.data);
    }
  };

  const getPaymentFourMonth = async (year) => {
    const params = {
      year: year,
      student_id: id,
    };
    const route = "PaymentFourMonth";
    const send = await insertDataJson(`${route}/${id}`, params);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const getInfoPayment = (item, fourMountn) => {
    setInfoPayment({
      ...infoPayment,
      amount: item.monthly_payment,
      surcharge: item.surcharge_payment,
      description: "Pago de mensualidad",
      month: item.description,
      four_month: fourMountn,
      month_id: item.id,
      student_id: id,
      method_payment:
        item.paid_status === "paid" || item.paid_status === "surchargePaid"
          ? item.method_payment
          : userData.abbreviation === "student"
          ? "Transferencia"
          : "Efectivo",
      type: "month",
      info_bill: item.info_bill,
      code: item.code,
      evidence: item.evidence ? item.evidence : "",
      paid_status: item.paid_status,
      discount: 0,
      email_student: studentData.email,
      you_can_pay: item.you_can_pay,
    });
  };

  const toListYear = () => {
    const year = new Date().getFullYear();
    setInfoPayment({
      ...infoPayment,
      year: year,
    });
    let years = [];
    for (let i = year - 5; i <= year; i++) {
      years.push(i);
    }
    setListYear(years);
  };

  const changeYear = (e) => {
    setYearSelected(e.target.value);
    setInfoPayment({
      ...infoPayment,
      year: e.target.value,
    });
    getPaymentFourMonth(e.target.value);
  };

  useEffect(() => {
    getStudent();
    toListYear();
    const year = new Date().getFullYear();
    setYearSelected(year);
    getPaymentFourMonth(year);
  }, []);

  const ListMonthPayment = ({ item, month, statusPayment, fourMountn }) => {
    const labelStatus =
      statusPayment === "paid"
        ? "Pagado"
        : statusPayment === "surcharge"
        ? "Recargo"
        : statusPayment === "surchargePaid"
        ? "Pagado Recargo"
        : "Programado";

    let iconStatus =
      statusPayment === "paid"
        ? parseInt(item.status) === 1 ? "fa-square-check text-orange-500 " : "fa-square-check text-green-500 "
        : statusPayment === "surcharge"
        ? "fa-square-xmark text-red-500"
        : statusPayment === "surchargePaid"
        ? parseInt(item.status) === 1 ? "fa-square-check text-orange-500 " : "fa-square-check text-green-500 "
        : "fa-square-xmark text-slate-300";
    iconStatus = "fa-solid text-xl " + iconStatus;

    let iconDocumentStatus =
      statusPayment === "paid"
        ? "fa-file text-slate-700 block cursor-pointer"
        : statusPayment === "surchargePaid"
        ? "fa-coins text-slate-700 block cursor-pointer"
        : "fa-square text-slate-100";
    iconDocumentStatus = "fa-solid text-xl " + iconDocumentStatus;

    return (
      <div className="grid grid-cols-2">
        <div className="flex items-center mb-1">
          <label className="ml-2 text-sm font-medium">
            <i className="fa-regular fa-square-check text-xl text-green-500"></i>
          </label>
          <label
            onClick={() => getInfoPayment(item, fourMountn)}
            className="block cursor-pointer ml-2 mr-10 text-sm font-medium text-gray-500"
          >
            {month}
          </label>
        </div>
        <div className="flex items-center">
          <label className="ml-2 text-sm font-medium">
            <i className={iconStatus}></i>
          </label>
          <label className="ml-2 text-sm font-medium">
            {statusPayment === "paid" || statusPayment === "surchargePaid" ? (
              <PDFDownloadLink
                document={
                  <PDFReceipt
                    name={studentData.name ? studentData.name : ""}
                    curp={studentData.curp ? studentData.curp : ""}
                    item={item}
                    year={yearSelected}
                  />
                }
                fileName={`Recibo_${item.description}`}
              >
                <i className={iconDocumentStatus}></i>
              </PDFDownloadLink>
            ) : (
              <i className={iconDocumentStatus} alt="Holaaaa"></i>
            )}
          </label>
          <label className="ml-2 text-sm font-medium text-gray-400">{labelStatus}</label>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1">
      <div className="">
        <TextField
          sx={{ m: 1 }}
          id="years"
          name="years"
          label="Año"
          value={infoPayment.year}
          size="small"
          select
          SelectProps={{
            native: true,
          }}
          onChange={(e) => changeYear(e)}
        >
          <option value="0">Seleccione una opción</option>
          {listYear.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </div>
      <div className="grid grid-cols-2">
        <div className="flex justify-center">
          <div className="w-96">
            <p className="font-bold mb-5">Cuatrimestes</p>
            {data.map((four) => (
              <div key={four.id}>
                <p className="my-3">{four.description}</p>
                {four.months.map((month) => (
                  <ListMonthPayment
                    item={month}
                    fourMountn={four.description}
                    key={month.id}
                    month={month.description}
                    statusPayment={month.paid_status}
                  />
                ))}
              </div>
            ))}

            {/* <p className="my-3">1er. Cuatrimestre</p>
            <ListMonthPayment month="Enero" statusPayment="paid" />
            <ListMonthPayment month="Febrero" statusPayment="surchargePaid" />
            <ListMonthPayment month="Marzo" statusPayment="surcharge" />
            <ListMonthPayment month="Abril" statusPayment="programmed" /> */}
          </div>
        </div>
        <PaymentCashModule
          infoPayment={infoPayment}
          setInfoPayment={setInfoPayment}
          refreshList={getPaymentFourMonth}
          studentData={studentData}
        />
      </div>
    </div>
  );
};

export default PaymentDetail;
