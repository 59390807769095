import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppProvider = (props) => {
  const [studentData, setStudentData] = useState({
    name: "",
    curp: "",
    email: "",
    abbreviation: "",
    career_name: "",
    student_id: 0,
  });

  return (
    <AppContext.Provider
      value={{
        studentData,
        setStudentData,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
